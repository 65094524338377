
import { Container } from '../components/Layout';
import { Tile } from '../components/Display';
import { Vocabulary } from '../data/VocabUnits';

export function ErrPage({language,theme}){
    return (
      <Container>
        <Tile theme={theme}>
          {Vocabulary[language].ErrPage}
        </Tile>
      </Container>
    )
  }

  