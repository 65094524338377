import { KEY_SEX, KEY_MALE,
    KEY_AGE, 
    KEY_MASS, 
    KEY_HEIGHT, 
    KEY_NECK, 
    KEY_WAIST,
    KEY_NAVEL, 
    KEY_HIPS, 
    KEY_BODYFAT,
    KEY_LEANBODYMASS,
    KEY_METABOLICRATE,
    KEY_TIMESTAMP,
} from '../data/DataKeys';
import { Vocabulary, Units } from '../data/VocabUnits';
import { UtcToLocal } from '../data/Calcs';
const MeasurementDetail = (props) => {
    const data = props.data;
    const language = props.language;
    const unit = props.unit;

    const detailStyle = { 
        minWidth:'350px',
        margin:'5px',
        display:'flex',
        flex:1,
        flexDirection:'column',
        justifyContent:'space-evenly'    
    }
    const rowStyle = {
        display:'flex',
        flex:1,
        flexDirection:'row',
    }
    const cellLeftStyle = {
        display:'flex',
        flex:1,
        padding:'3px 10px',
        textAlign:'left'
    }
    const cellRightStyle = {
        display:'flex',
        flexShrink:0,
        padding:'3px 10px',
        justifyContent:'right'
    }

    const mf = data[KEY_SEX] === KEY_MALE ? Vocabulary[language].Male : Vocabulary[language].Female;

    return (
        <div style={detailStyle}>
            {props.showTimestamp ? 
                <div style={rowStyle}>
                    <div style={cellLeftStyle}>{Vocabulary[language].Timestamp}</div>
                    <div style={cellRightStyle}> {data[KEY_TIMESTAMP] ? UtcToLocal(data[KEY_TIMESTAMP]) : '--'}</div>                
                </div>
            : null }

            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].Sex}</div>
                <div style={cellRightStyle}> {
                    mf
                    }
                </div>
            </div>
            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].Age}</div>
                <div style={cellRightStyle}> {data[KEY_AGE]}</div>                
            </div>
            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].Mass}</div>
                <div style={cellRightStyle}> {data[KEY_MASS]} {Units[unit].kg}</div>                
            </div>
            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].Height}</div>
                <div style={cellRightStyle}> {data[KEY_HEIGHT]} {Units[unit].cm}</div>                
            </div>
            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].Neck}</div>
                <div style={cellRightStyle}> {data[KEY_NECK]} {Units[unit].cm}</div>                
            </div>
            {data[KEY_SEX] === KEY_MALE ? 
                <>
                    <div style={rowStyle}>
                        <div style={cellLeftStyle}>{Vocabulary[language].Navel}</div>
                        <div style={cellRightStyle}> {data[KEY_NAVEL]} {Units[unit].cm}</div>
                    </div>
                </>
            :
                <>
                    <div style={rowStyle}>
                            <div style={cellLeftStyle}>{Vocabulary[language].Waist}</div>
                            <div style={cellRightStyle}> {data[KEY_WAIST]} {Units[unit].cm}</div>
                    </div>
                    <div style={rowStyle}>
                        <div style={cellLeftStyle}>{Vocabulary[language].Hips}</div>
                        <div style={cellRightStyle}> {data[KEY_HIPS]} {Units[unit].cm}</div>
                    </div>
                </>
            }                    
            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].BodyFat}</div>
                <div style={cellRightStyle}> {data[KEY_BODYFAT] ? data[KEY_BODYFAT].toFixed(2) : '--' } %</div>                
            </div>
            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].LeanBodyMass }</div>
                <div style={cellRightStyle}> {data[KEY_LEANBODYMASS] ? data[KEY_LEANBODYMASS].toFixed(2) : '--' } {Units[unit].kg}</div>                
            </div>
            <div style={rowStyle}>
                <div style={cellLeftStyle}>{Vocabulary[language].MetabolicRate }</div>
                <div style={cellRightStyle}> {data[KEY_METABOLICRATE] ? data[KEY_METABOLICRATE].toFixed(0) : '--' } {Vocabulary[language].kcalPerday}</div>                
            </div>
        </div>
    )
}


export {MeasurementDetail};