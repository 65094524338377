import {Container, Row, CellLeft } from '../components/Layout';
import { Tile, Label } from '../components/Display';
import {Vocabulary} from '../data/VocabUnits';

export function  Info({language,theme}){
    return (
      <Container>
        <Tile theme={theme}>
        <Label text={Vocabulary[language].InfoPageHeader}/>
          <Row>
            <CellLeft>
              {Vocabulary[language].InfoPageDesc}
            </CellLeft>
          </Row>
        </Tile>
        <Tile theme={theme}>
        <Label text={Vocabulary[language].HowToUse}/>
          <Row>
            <CellLeft>
              {Vocabulary[language].HowToUseInstructions}
            </CellLeft>
          </Row>
        </Tile>
        <Tile theme={theme}>
        <Label text={Vocabulary[language].PrivacyPolicy}/>
          <Row>
            <CellLeft>
              {Vocabulary[language].PrivacyPolicyDesc}
            </CellLeft>
          </Row>
        </Tile>
        <Tile theme={theme}>
        <Label text={Vocabulary[language].CopyRight}/>
          <Row>
            <CellLeft>
              {Vocabulary[language].CopyRightDesc}
            </CellLeft>
          </Row>
        </Tile>
      </Container>
    )
  }