import React, {useState, useEffect} from 'react';
import { LocalData } from '../data/LocalData';
import { Vocabulary, Units } from '../data/VocabUnits';
import { InitialMeasurement } from '../data/Data';
import { Container, Row, Cell, } from '../components/Layout';
import { Notice, Tile,  } from '../components/Display';
import { BodyFat, BasalMetobolicRate, LeanBodyMass, UtcMilli} from '../data/Calcs'
import { ImageButton, InputDecimal, InputNumber, DropDownSelect, DropDownOption} from '../components/Input';
import { MeasurementDetail } from '../components/MeasurementDetail';
import {
    KEY_MEASUREMENT,KEY_MEASUREMENTS,
    KEY_TIMESTAMP,
    KEY_SEX,KEY_MALE,KEY_FEMALE,KEY_AGE,KEY_MASS,
    KEY_HEIGHT,KEY_NECK,KEY_WAIST,KEY_NAVEL,KEY_HIPS,
    KEY_BODYFAT,KEY_LEANBODYMASS,KEY_METABOLICRATE
  } from '../data/DataKeys';

import create512 from '../assets/create512.png';
import refresh512 from '../assets/refresh512.png';
//
const appSex = localStorage.getItem(KEY_SEX) ?? KEY_MALE;
// form ui component
export function Form({language,unit,theme}) {
  const [formSet, isFormSet] = useState(false);
  const [formSubmit, isFormSubmit] = useState(false);
  const [sex, setSex] = useState(appSex);
  const [measurement, setMeasurement] = useState(InitialMeasurement);

  // get the measurement used in form
  function getMeasurement(){
      const data = LocalData.getJSON(KEY_MEASUREMENT);
      if(data){
        setMeasurement({...data}) ;
      } 
  } 
  // set set MF dropdown and measurement value
  function onSetSex(key, val){
    LocalData.setKey(KEY_SEX, val);
    setInput(key, val);
    setSex(val);
    
  };
  // set the measurement object key's value
  function setInput(key, val) {
      setMeasurement({ ...measurement, [key]: val });
  } 
  // recalculate stats
  function recalc(){
    measurement[KEY_SEX] = sex;
    if((measurement.ageYR && measurement.massKG && measurement.heightCM && measurement.neckCM && measurement.navelCM) ||
      (measurement.ageYR && measurement.massKG && measurement.heightCM && measurement.neckCM && measurement.waistCM && measurement.hipsCM)){
        const newMeasurement = measurement;
        const utc = UtcMilli().toString();
        newMeasurement[KEY_TIMESTAMP] = utc;
        //
        const bodyfat = BodyFat(
          measurement.sexMF,
          measurement.heightCM,
          measurement.neckCM,
          measurement.waistCM,
          measurement.navelCM,
          measurement.hipsCM
        );
        newMeasurement[KEY_BODYFAT] = bodyfat;
        //
        const metabolicrate = BasalMetobolicRate(
          measurement.sexMF,
          measurement.ageYR,
          measurement.heightCM,
          measurement.massKG
        );
        newMeasurement[KEY_METABOLICRATE] = metabolicrate;
        //
        const leanbodymass = LeanBodyMass(
          measurement.massKG,
          measurement.bodyfat
        );
        newMeasurement[KEY_LEANBODYMASS] = leanbodymass;
        //
        setMeasurement({...newMeasurement})
      } else {
        const newMeasurement = measurement;
        newMeasurement[KEY_TIMESTAMP] = 0;
        newMeasurement[KEY_BODYFAT] = 0;
        newMeasurement[KEY_METABOLICRATE] = 0;
        newMeasurement[KEY_LEANBODYMASS] = 0;
        setMeasurement({...newMeasurement})
      }
  }
  // save a measurement 
  function submitForm(){
    if((measurement.ageYR !== '' && measurement.massKG && measurement.heightCM && measurement.neckCM && measurement.navelCM) ||
        (measurement.ageYR && measurement.massKG && measurement.heightCM && measurement.neckCM && measurement.waistCM && measurement.hipsCM)){
        recalc();
        LocalData.setJSON(KEY_MEASUREMENT, measurement);
        LocalData.addJSON(KEY_MEASUREMENTS, measurement);
        isFormSet(false);
        isFormSubmit(true);
    } else {
        alert(Vocabulary[language].CompleteForm);
    }
  }
  // updates to DOM
  useEffect(()=>{
    if(!formSet){
      getMeasurement();
    }
    
    setTimeout(()=>{
      isFormSubmit(false);
    },3000);         
    
    return () => {
      isFormSet(true);
    }
  }, [formSet]);
  return(
    <Container>
    <Tile theme={theme}>
      <Row>
        <Cell>{Vocabulary[language].Conversions}</Cell>
      </Row>
      <Row>
        <Cell>
          {Vocabulary[language].lbPerKg}
        </Cell>
        <Cell>
          {Vocabulary[language].cmPerIn}
        </Cell>
      </Row>
      <Row>
          <Cell>{Vocabulary[language].Measure}</Cell>
      </Row>
      { sex === KEY_MALE ? 
        <>
          <Row>
              <Cell>{Vocabulary[language].NeckInstruction}</Cell>
            </Row> 
            <Row>
              <Cell>{Vocabulary[language].NavelInstruction}</Cell>
            </Row> 
        </>
      : 
        <>
          <Row>
              <Cell>{Vocabulary[language].NeckInstruction}</Cell>
            </Row> 
          <Row>
              <Cell>{Vocabulary[language].WaistInstruction}</Cell>
          </Row>
          <Row>   
              <Cell>{Vocabulary[language].HipsInstruction}</Cell>
          </Row> 
        </>
      }
      <Row>
        <DropDownSelect  
          onChange={(e)=>onSetSex(KEY_SEX,e.target.value)}
          value={sex}
        > 
          <DropDownOption value={KEY_FEMALE} text={Vocabulary[language].Female}/>
          <DropDownOption value={KEY_MALE} text={Vocabulary[language].Male} />
        </DropDownSelect>   
      </Row>
      <Row>
        <InputNumber
          placeholder={Vocabulary[language].Age} 
          onChange={(e)=>setInput(KEY_AGE,e.target.value)}
          value={measurement[KEY_AGE]}  
        />                 
      </Row> 
      <Row>
        <InputDecimal 
          step={0.01}
          placeholder={Vocabulary[language].Mass+' '+Units[unit].kg} 
          onChange={(e)=>setInput(KEY_MASS,e.target.value)}
          value={measurement[KEY_MASS]}
        />
        <InputDecimal 
          step={0.01}
          placeholder={Vocabulary[language].Height+' '+Units[unit].cm} 
          onChange={(e)=>setInput(KEY_HEIGHT,e.target.value)}
          value={measurement[KEY_HEIGHT]}  
        />      
        </Row>
        <Row>
          <InputDecimal 
            step={0.01}
            placeholder={Vocabulary[language].Neck+' '+Units[unit].cm} 
            onChange={(e)=>setInput(KEY_NECK,e.target.value)}
            value={measurement[KEY_NECK]}  
          />
          { sex === KEY_MALE ? 
            <>
              <InputDecimal 
                step={0.01}
                placeholder={Vocabulary[language].Navel+' '+Units[unit].cm} 
                onChange={(e)=>setInput(KEY_NAVEL,e.target.value)}
                value={measurement[KEY_NAVEL]}  
              />   
            </>
          :
            <>
            <InputDecimal 
                step={0.01}
                placeholder={Vocabulary[language].Waist+' '+Units[unit].cm} 
                onChange={(e)=>setInput(KEY_WAIST,e.target.value)}
                value={measurement[KEY_WAIST]}  
              />
              <InputDecimal 
                step={0.01}
                placeholder={Vocabulary[language].Hips+' '+Units[unit].cm} 
                onChange={(e)=>setInput(KEY_HIPS,e.target.value)}
                value={measurement[KEY_HIPS]}  
              />
            </> 
          }                
        </Row>
        <Row>
          <Notice visible={formSubmit} msg={Vocabulary[language].SubmitSuccess}/>
        </Row>
        <Row>
          <Cell>
            <ImageButton 
              image={refresh512} 
              onClick={()=>recalc()}
              title={Vocabulary[language].Recalc}
              theme={theme}
            />
          </Cell>
        <Cell>
            <ImageButton 
              image={create512} 
              onClick={(e)=>submitForm(e)}
              title={Vocabulary[language].SubmitForm}
              theme={theme}
            />
          </Cell>
        </Row>
        <MeasurementDetail 
          data={measurement} 
          language={language} 
          unit={unit}
          theme={theme}
        />
    </Tile> 
  </Container>   
  )
}