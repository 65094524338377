import { KEY_THEME_DARK } from "../data/DataKeys"

//
const Label = (props) => {
    const darkStyle = {
        display:'flex',
        flex:1,
        flexDirection:'row',
        justifyContent:'center',
        fontSize:'calc(20px + 1vh)',
    }
    const lightStyle = {
        display:'flex',
        flex:1,
        flexDirection:'row',
        justifyContent:'center',
        fontSize:'calc(20px + 1vh)',
    }
    const theme = (props.theme === KEY_THEME_DARK ? darkStyle : lightStyle);
    return (
        <div style={theme}>
            {props.text}
        </div>
    )
}
// 
const Logo = (props) => {
    const darkStyle = {
        height:props.height,
        width:props.width,
    }
    const lightStyle = {
        height:props.height,
        width:props.width,
    }
    const theme = (props.theme === KEY_THEME_DARK ? darkStyle : lightStyle);
    return (
        <img 
            src={props.src} 
            alt={props.alt}
            style={theme}
        />
    )
}
//
const Notice = (props) => {
    return (
        <>
            {props.visible ? 
                <div>
                    {props.msg}
                </div>
            : null}
        </>
        
    )
}
//
const Page = (props) => {
    const darkStyle = {
        backgroundColor: '#333842',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 'calc(14px + 1vmin)',
        color: 'white',
        margin:'0px',
        padding:'10px',
    }
    const lightStyle = {
        backgroundColor: '#fff',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 'calc(14px + 1vmin)',
        color: 'black',
        margin:'0px',
        padding:'10px',
    }
    const theme = (props.theme === KEY_THEME_DARK ? darkStyle : lightStyle);
    return (
        <div style={theme}>
            {props.children}
        </div>
    )
}
//
const Tile = (props) => {
    const darkStyle = {
        width:'350px',
        margin:'5px',
        padding:'10px',
        display:'flex',
        flex:1,
        flexDirection:'column',
        justifyContent:'space-evenly',
        border:'1px solid #070709',
        backgroundColor:'#282c34',
        color:'#cad2d6',
        fontSize: 'calc(14px + 1vmin)',
    }
    const lightStyle = {
        width:'350px',
        margin:'5px',
        padding:'10px',
        display:'flex',
        flex:1,
        flexDirection:'column',
        justifyContent:'space-evenly',
        border:'1px solid #111',
        backgroundColor:'#eee',
        color:'#111',
        fontSize: 'calc(14px + 1vmin)',
    }
    const theme = (props.theme === KEY_THEME_DARK ? darkStyle : lightStyle);
    return (
        <div style={theme}>
            {props.children}
        </div>
    )
} 
export {Label,Logo,Notice,Page,Tile,}
    
    
    
    
    
