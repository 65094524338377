const KEY_VIEW = 'view'
const KEY_LANGUAGE = 'language';
const KEY_THEME = 'theme';
const KEY_UNIT = 'unit';
//
const KEY_THEME_DARK = 'dark';
const KEY_THEME_LIGHT = 'light';
//
const KEY_MEASUREMENT = 'measurement';// store one
const KEY_MEASUREMENTS = 'measurements';//store list
const KEY_ID = 'id';
const KEY_TIMESTAMP = 'timestamp';
const KEY_SEX = 'sexMF';
const KEY_MALE = 'male';
const KEY_FEMALE = 'female';
const KEY_AGE = 'ageYR';
const KEY_MASS = 'massKG';
const KEY_HEIGHT = 'heightCM';
const KEY_NECK = 'neckCM';
const KEY_WAIST = 'waistCM';
const KEY_NAVEL = 'navelCM';
const KEY_HIPS = 'hipsCM';
const KEY_BODYFAT = 'bodyfat';
const KEY_LEANBODYMASS = 'leanBodyMassKG';
const KEY_METABOLICRATE = 'metabolicRate';

export {
    KEY_VIEW,
    KEY_LANGUAGE,
    KEY_THEME, KEY_THEME_DARK, KEY_THEME_LIGHT,
    KEY_UNIT,
    KEY_MEASUREMENT,KEY_MEASUREMENTS,
    KEY_ID, KEY_TIMESTAMP,
    KEY_SEX,KEY_MALE,KEY_FEMALE,
    KEY_AGE,
    KEY_MASS,
    KEY_HEIGHT,KEY_NECK,KEY_WAIST,KEY_NAVEL,KEY_HIPS,
    KEY_BODYFAT,KEY_LEANBODYMASS,KEY_METABOLICRATE
}