// a blank measurement object. 
const InitialMeasurement = {
    id:'',            //generated ai
    timestamp:'',     //generated utc
    sexMF:'',         //input boolean male = 1, female = 0
    ageYR:'',         //input years
    heightCM:'',      //input height
    massKG:'',        //input mass 
    neckCM:'',        //input circumference
    waistCM:'',       //input circumference (females only)
    navelCM:'',       //input circumference (males only)
    hipsCM:'',        //input circumference (females only)
    bodyfat:'',       //calc precent
    leanBodyMassKG:'',//calc number
    metabolicRate:'', //calc number
}

  
export {
    InitialMeasurement
};