
const Container = (props) => {
    const containerStyle = {
        display: 'flex',
        flexDirection:'column'
    }
    return (
        <div style={containerStyle}>
            {props.children}
        </div>
    )
}
//
const Row = (props) => {
    const rowStyle = {
        display:'flex',
        flex:1,
        flexDirection:'row',
        flexWrap:'wrap',
        alignItems:'flex-start',
        justifyContent:'center',
        margin:'3px'
    }
    return (
        <div style={rowStyle}>
            {props.children}
        </div>
    )
}
//
const Cell = (props) => {
    const cellStyle = {
        display:'flex',
        flex:1,
        justifyContent:'space-evenly'
    }
    return (
        <div style={cellStyle}>
            {props.children}
        </div>
    )
}
//
const CellLeft = (props) => {
    const cellStyle = {
        display:'flex',
        flex:1,
        justifyContent:'left',
        alignItems:'center'
    }
    return (
        <div style={cellStyle}>
            {props.children}
        </div>
    )
}


export {Container, Row, Cell, CellLeft}