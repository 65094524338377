import React, {useState, useEffect} from 'react';
import { Container, Row, Cell} from '../components/Layout';
import { Tile } from '../components/Display';
import { LocalData } from '../data/LocalData';
import { Vocabulary } from '../data/VocabUnits';
import { MeasurementDetail } from '../components/MeasurementDetail';
import { ImageButton} from '../components/Input';
import delete512 from '../assets/delete512.png';
import {KEY_MEASUREMENTS,KEY_TIMESTAMP,} from '../data/DataKeys';

export function List({language,theme,unit}){
    const [listSet, isListSet] = useState(false);
    const [list, setList] = useState([]);

    // detele a measurement record by timestamp
    function deleteMeasurementByTimestamp(timestamp){
        const dlt = window.confirm(Vocabulary[language].ContinueToDelete);
        if(dlt === true){
            const newList = list.filter((record) => record[KEY_TIMESTAMP] !== timestamp);
            LocalData.setJSON(KEY_MEASUREMENTS, newList);
            setList(newList);
            isListSet(false);      
        }
    }
    // get the current list of local measurements
    function getMeasurementList(){
        const list = LocalData.getJSON(KEY_MEASUREMENTS);
        // order timestamp DESC
        if((typeof list !== 'undefined') && (list.length > 0)){
            const sort = list ? list.sort((a,b)=>a.timestamp < b.timestamp ? 1 : -1) : [];
            setList(sort);     
        }
    }

    useEffect(() => {
        // get data
        if(!listSet){
          getMeasurementList();
        }
        // clean up
        return () => {
          isListSet(true);
        }
    }, [listSet]);

    return (
        <Container>
        {list.map((record,index)=>(
          <Tile key={index} theme={theme}>
            <MeasurementDetail 
              data={record} 
              language={language} 
              unit={unit}
              showTimestamp={true}
            />        
            <Row>  
                <ImageButton 
                  image={delete512}
                  title={Vocabulary[language].DeleteMeasurement} 
                  onClick={()=>deleteMeasurementByTimestamp(record[KEY_TIMESTAMP])}
                  theme={theme}
                />
            </Row>
          </Tile>
          ))}
          {list.length === 0 ? 
            <Row>
              <Cell>
                {Vocabulary[language].NoHealthStats}
              </Cell>
            </Row>
          : null
          }
      </Container>  
    )
}