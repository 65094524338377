
const Vocabulary = {
    en:{
        Age:'Age',
        AllStats:'All Stats.',
        BodyFat:'Body Fat',
        ButtonForm:'Calculate health stats.',
        ButtonInfo:'Learn more about Calc. Health',
        ButtonList:'View your saved health stats.',
        ButtonSettings:'Settings and data.',
        Calories:'Calories',
        cmPerIn:'2.54 cm/in',
        Circumference:'Circumference',
        CompleteForm:'Complete all input fields!',
        ContinueToDelete:'Continue to delete this record? This cannot be undone.',
        ContinueToDeleteAll:'Continue to delete ALL record? This cannot be undone.',
        Conversions:'Conversions:',
        CopyRight:'Copy Right',
        CopyRightDesc:'All rights reserved.',
        DeleteAllData:'Delete All Data',
        DeleteMeasurement:'Delete Measurement',
        ErrPage:'Select an icon above.',
        Female:'Female',
        HealthStat:'Calc. Health',
        Height:'Height',
        Hips:'Hips',
        HipsInstruction:'Hips at widest.',
        HowToUse:'How to use.',
        HowToUseInstructions:`To change views, use the buttons in the navigation bar.
            Health statistics can be calculated by entering body measurements in the form 
            and saved measurements can be viewd in the list. Users can also access and delete 
            his/her data and change settings in the settings view. 
        `,
        InfoPageDesc:`Calc Health is a simple tool used to calculate body fat, 
            lean body mass, and metabolic rate. This application is also the 
            practical component discussed in the white paper ZeroToReact by Michael Shermot.`,
        InfoPageHeader:'Info.',
        Intensity:'Intensity',
        kcalPerday:'cal/day',
        kcal300:'300 cal/hr',
        kcal500:'500 cal/hr',
        lbPerKg:'2.205 lb/kg',
        Language:'Language',
        LogoAlt:'Calc Health. Logo',
        LeanBodyMass:'Lean Body Mass',
        Level0:'No Exercise',
        Level1:'30 minues',
        Level2:'1 hour',
        Level3:'3 hours ',
        Level4:'5 hours',
        Mass:'Mass',
        Male:'Male',
        Measure:'Measure:',
        MetabolicRate:'Metabolic Rate',
        Navel:'Navel ',
        NavelInstruction:'Navel at belly-button.',
        Neck:'Neck',
        NeckInstruction:'Neck at narrowest.',
        NoHealthStats:'No saved health stats.',
        PrivacyPolicy:'Privacy Policy',
        PrivacyPolicyDesc:'No user data is tracked or stored.',
        Recalc:'Recalculate stats.',
        Sex:'Sex',
        Settings:'Settings',
        Submit:'Submit',
        SubmitForm:'Save Stats.',
        SubmitSuccess:'Stats. saved successfully.',
        TermsOfService:'Terms of Service',
        TermsOfServiceDesc:'',
        Theme:'Theme: ',
        ThemeDark:'Dark',
        ThemeLight:'Light',
        Timestamp:'Date',
        TrainingAmount:'Training Amount',
        TrainingIntensity:'Training Intensity',
        VocabularyLanguage:'English',
        Waist:'Waist',
        WaistInstruction:'Waist at narrowest.',
        Weight:'Weight',
    },
    es:{
        Age:'Anos',
        AllStats:'Todos estadísticas.',
        BodyFat:'Grasa corporal',
        ButtonForm:'Calculas salud estadísticas',
        ButtonInfo:'Más información sobre el cálculo de salud.',
        ButtonList:'Ver sus estadísticas de salud guardadas.',
        ButtonSettings:'Configuración y datos.',
        Calories:'Calorías',
        cmPerIn:'2.54 cm/in',
        Circumference:'Circunferencia',
        CompleteForm:'¡Complete todos los campos de entrada!',
        ContinueToDelete:'¿Continuar eliminando este registro? Esto no se puede deshacer.',
        ContinueToDeleteAll:'¿Continuar eliminando TODOS los registros? Esto no se puede deshacer.',
        Conversions:'Conversiones',
        CopyRight:'Derechos de autor.',
        CopyRightDesc:'Reservados todos los derechos',
        DeleteAllData:'Eliminar todos los datos',
        DeleteMeasurement:'Eliminar medición',
        ErrPage:'Seleccione un icono de arriba.',
        Female:'Mujer',
        HealthStat:'Calculas Salud',
        Height:'Altura',
        Hips:'Caderas',
        HipsInstruction:'Caderas más anchas.',
        HowToUse:'Cómo utilizar.',
        HowToUseInstructions:`Para cambiar las vistas, use los botones de la barra de navegación.
        Las estadísticas de salud se pueden calcular ingresando las medidas corporales en el formulario
        y las mediciones guardadas se pueden ver en la lista. Los usuarios también pueden acceder y eliminar
        sus datos y cambiar la configuración en la vista de configuración.`,
        InfoPageDesc:`Calculas Salud es una herramienta sencilla que se utiliza para calcular la grasa corporal,
        masa corporal magra y tasa metabólica. Esta aplicación también es la
        componente práctico discutido en el libro blanco ZeroToReact de Michael Shermot.`,
        InfoPageHeader:'Información',
        Intensity:'Intensidad.',
        kcalPerday:'cal/dia',
        kcal300:'300 cal/hr',
        kcal500:'500 cal/hr',
        Language:'Idioma',
        lbPerKg:'2.205 lb/kg',
        LogoAlt:'Logotipo de salud de cálculo',
        LeanBodyMass:'Masa corporal magra.',
        Level0:'No ejercicio',
        Level1:'30 minuto',
        Level2:'1 hora',
        Level3:'3 horas ',
        Level4:'5 horas',
        Mass:'Masa',
        Male:'Hombre',
        Measure:'La medida' ,
        MetabolicRate:'Tasa metabólica',
        Navel:'Ombligo',
        NavelInstruction:'Ombligo a la altura del ombligo.',
        Neck:'Cuello.',
        NeckInstruction:'Cuello más estrecho.',
        NoHealthStats:'Sin estadísticas de salud guardadas',
        PrivacyPolicy:'Política de privacidad',
        PrivacyPolicyDesc:'No se rastrea ni se almacena ningún dato de usuario.',
        Recalc:'Recalcular estadísticas',
        Sex:'Sexo',
        Settings:'Ajustes',
        Submit:'Entregars',
        SubmitForm:'Guardar estadística',
        SubmitSuccess:'Estadísticas guardadas con éxito',
        Theme:'Teme',
        ThemeDark:'Oscuro',
        ThemeLight:'Brillante',
        Timestamp:'Marca de tiempo',
        TrainingAmount:'Cantidad de entrenamiento',
        TrainingIntensity:'Intensidad de entrenamiento',
        VocabularyLanguage:'Espanol',
        Waist:'Cintura',
        WaistInstruction:'Cintura en el más estrecho.',
        Weight:'Peso',
    }
}

const Units = {
    english:{
        in:'in',
        lb:'lb',
    },
    metric:{
        cm:'cm',
        kg:'kg'
    }
}

export {Units,Vocabulary};