import {KEY_MALE,KEY_FEMALE} from './DataKeys';

// equations from: https://www.bizcalcs.com/body-fat-navy/
// male = 495 / (1.0324 - .19077 * log10(Navel in cm - Neck in cm) + .15456 * log10(Height in cm)) - 450
// female = 495 / (1.29579 - .35004 * log10(Waist in cm + Hip in cm - Neck in cm) + .22100 * log10(Height in cm)) - 450
function BodyFat(maleFemale,heightCM,neckCM,waistCM,navalCM,hipCM){
    maleFemale = (typeof maleFemale !== 'undefined') ? maleFemale : 0;
    heightCM = (typeof heightCM !== 'undefined') ?  parseFloat(heightCM) : 0;
    neckCM = (typeof neckCM !== 'undefined') ?  parseFloat(neckCM) : 0;
    waistCM = (typeof waistCM !== 'undefined') ?  parseFloat(waistCM) : 0;
    navalCM = (typeof navalCM !== 'undefined') ?  parseFloat(navalCM) : 0;
    hipCM = (typeof hipCM !== 'undefined') ?  parseFloat(hipCM) : 0;
    let bf;// estimated total body fate
    if(maleFemale === KEY_MALE){
        bf = 495 / (1.0324 - .19077 * Math.log10(navalCM - neckCM) + .15456 * Math.log10(heightCM)) - 450
    } else if(maleFemale === KEY_FEMALE){
        bf = 495 / (1.29579 - .35004 * Math.log10(waistCM + hipCM - neckCM) + .22100 * Math.log10(heightCM)) - 450
    }
    return bf;
}

// equations from: HARRIS AND BENEDICT EQUATION : 
// https://blog.nasm.org/nutrition/resting-metabolic-rate-how-to-calculate-and-improve-yours
// male = 88.362 + (13.397 * mass in kg) + (4.799 * height in cm) - (5.677 * age in years)
// female = 447.593 + (9.247 * mass in kg) + (3.098 * height in cm) - (4.330 * age in years)
function BasalMetobolicRate(maleFemale,ageYR,heightCM,massKG){
    maleFemale = (typeof maleFemale !== 'undefined') ?  maleFemale : 1;
    ageYR = (typeof ageYR !== 'undefined') ?  ageYR : 0;
    massKG = (typeof massKG !== 'undefined') ?  massKG : 0;
    heightCM = (typeof heightCM !== 'undefined') ?  heightCM : 0;
    let bmr; // estimated total calories burned by being alive
    if(maleFemale === KEY_MALE){
        bmr = 88.362 + (13.397 * massKG) + (4.799 * heightCM) - (5.677 * ageYR);
    } else if(maleFemale === KEY_FEMALE){
        bmr = 447.593 + (9.247 * massKG) + (3.098 * heightCM) - (4.330 * ageYR);
    }
    return bmr; 
}

// mass - (mass in body fat)
function LeanBodyMass(massKG,bodyFatP) {
    massKG = (typeof massKG !== 'undefined') ?  massKG : 0;
    bodyFatP = (typeof bodyFatP !== 'undefined') ?  bodyFatP : 0;
    const lbm = massKG - (massKG * bodyFatP * 1/100);
    return lbm;
}

function UtcMilli() {
    const date = new Date();
    const milli = date.getTime();
    return milli;
}

function UtcToLocal(utcMilli) {
    const utc = parseInt(utcMilli);
    var d = new Date(utc),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = '' + d.getFullYear(),
    hours = ''+ d.getHours(),
    mins = '' + d.getMinutes(),
    secs = '' + d.getSeconds();

    if (month.length < 2) 
        {month = '0' + month};
    if (day.length < 2) 
        {day = '0' + day};
    if (hours.length < 2)
        {hours = '0' + hours};
    if (mins.length < 2)
        {mins = '0' + mins};
    if (secs.length < 2)
        {secs = '0' + secs};
    const date = [year, month, day].join('-');
    const time = [hours, mins, secs].join(':');
    const datetime = [date,time].join(' ');
return datetime
}

export {
    BodyFat,
    BasalMetobolicRate,
    LeanBodyMass,
    UtcMilli,
    UtcToLocal
}

