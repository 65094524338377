import { Tile, Label } from '../components/Display';
import { Container, Row, CellLeft} from '../components/Layout';
import { ImageButton, DropDownSelect, DropDownOption } from '../components/Input';
import { Vocabulary } from '../data/VocabUnits';
import { LocalData } from '../data/LocalData';
import { KEY_THEME_DARK, KEY_THEME_LIGHT, KEY_THEME, 
  KEY_VIEW, KEY_LANGUAGE, KEY_UNIT, KEY_SEX, 
  KEY_MEASUREMENT, KEY_MEASUREMENTS, } from '../data/DataKeys';
import delete512 from '../assets/delete512.png';

export function Settings({languageState, themeState}){
    const {language, setLanguage} = languageState;
    const {theme, setTheme} = themeState;
    const languages = Object.keys(Vocabulary);
    function deleteAllData(){
      const dlt = window.confirm(Vocabulary[language].ContinueToDeleteAll);
      if(dlt === true){
          LocalData.dltKey(KEY_MEASUREMENT);
          LocalData.dltKey(KEY_MEASUREMENTS);
          LocalData.dltKey(KEY_VIEW);
          LocalData.dltKey(KEY_LANGUAGE);
          LocalData.dltKey(KEY_THEME);
          LocalData.dltKey(KEY_UNIT);
          LocalData.dltKey(KEY_SEX);
      }
    }

    function onSetLanguage(value){
      LocalData.setKey(KEY_LANGUAGE,value);
      setLanguage(value);
    }

    function onSetTheme(value){
      LocalData.setKey(KEY_THEME,value);
      setTheme(value);
    }
    
    return (
      <Container>
        <Tile theme={theme}>
          <Label text={Vocabulary[language].Settings}/>
          <Row>
            <CellLeft>{Vocabulary[language].Theme}</CellLeft>
            <CellLeft>
              <DropDownSelect  
                onChange={(e)=>onSetTheme(e.target.value)}
                value={theme}
              > 
              <DropDownOption value={KEY_THEME_DARK} text={Vocabulary[language].ThemeDark}/>
              <DropDownOption value={KEY_THEME_LIGHT} text={Vocabulary[language].ThemeLight} />
            </DropDownSelect>   
            </CellLeft>
          </Row>
          <Row>
            <CellLeft>{Vocabulary[language].Language}</CellLeft>
            <CellLeft>
              <DropDownSelect  
              onChange={(e)=>onSetLanguage(e.target.value)}
              value={language}
            > 
            {languages.map((lang,l)=>(
              <DropDownOption 
                key={l}
                value={lang} 
                text={Vocabulary[lang].VocabularyLanguage}
              />
            ))}
            </DropDownSelect>   
            </CellLeft>
          </Row>
          <Row>
            <CellLeft>
              <ImageButton 
                image={delete512}
                title={Vocabulary[language].DeleteMeasurement} 
                onClick={()=>deleteAllData()}
                theme={theme}
              />
              {Vocabulary[language].DeleteAllData}
            </CellLeft>
          </Row>
        </Tile>
      </Container>
    )
  }