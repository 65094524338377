/** @class */
// LocalData can only be stored in text format.
// Arrays and Objects should be translated via
// JSON.stringfy(...);
export class LocalData {    
    // get a single key, returns a value
    // get a single obj stored as JSON
    static getKey(key){
        const val = localStorage.getItem(key);
        if(val){
          return val  
        }
    }
    // set a key with a value
    static setKey(key,val){
        localStorage.setItem(key,val);
    } 
    // get an array of objects   
    static getJSON(key){
        if (localStorage.getItem(key) !== null) {
            const jsonData = localStorage.getItem(key);
            const data = JSON.parse(jsonData);
            return data;
        }
    }
    // add to array of objects
    // obj -pass an object
    static addJSON(key,obj){
        let data = [];
        if(localStorage.getItem(key)===null){
            data.push(obj);
        }else{
            const jsonData = localStorage.getItem(key);
            data = JSON.parse(jsonData);
            data.push(obj);
        }
        const json = JSON.stringify(data);
        localStorage.setItem(key,json);
    }
    // set to array of objects
    static setJSON(key,arrObjs){
        const json = JSON.stringify(arrObjs);
        localStorage.setItem(key,json);
    }
    // delete the value of any key
    static dltKey(key){
        localStorage.removeItem(key);
    }
    // delete all local storage
    static dltAll(){
        localStorage.clear();
    }
}
