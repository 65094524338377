import React from 'react';
import { Vocabulary } from '../data/VocabUnits';
import { Tile, Label } from '../components/Display'
import {Container, Row, Cell} from '../components/Layout';
import { ImageButton} from '../components/Input';

import { Logo } from '../components/Display';
import logo512 from '../assets/logo512.png';
import info512 from '../assets/info512.png';
import list512 from '../assets/list512.png';
import settings512 from '../assets/settings512.png';
import { LocalData } from '../data/LocalData';
import { KEY_VIEW } from '../data/DataKeys';

export function Nav({viewState,language,theme}){
  // eslint-disable-next-line
  const {view, setView} = viewState;

  function onSetView(value){
    LocalData.setKey(KEY_VIEW, value);
    setView(value);
  }
  
  return (
    <Container>
      <Tile theme={theme}>
        <Row>
          <Logo 
            height={'50px'} 
            width={'50px'} 
            src={logo512} 
            alt={Vocabulary[language].LogoAlt}/>
        </Row>
        <Row>
          <Label text={Vocabulary[language].HealthStat}/>  
        </Row>    
        <Row>
          <Cell>
            <ImageButton 
              image={logo512}
              onClick={()=>onSetView('form')}
              title={Vocabulary[language].ButtonForm}
              theme={theme}
            />
          </Cell>
          <Cell>
            <ImageButton 
              image={info512}
              onClick={()=>onSetView('info')}
              title={Vocabulary[language].ButtonInfo}
              theme={theme}
            />
          </Cell>
          <Cell>
            <ImageButton 
              image={list512}
              onClick={()=>onSetView('list')}
              title={Vocabulary[language].ButtonList}
              theme={theme}
            />
          </Cell>
          <Cell>
            <ImageButton 
              image={settings512}
              onClick={()=>onSetView('settings')}
              title={Vocabulary[language].ButtonSettings}
              theme={theme}
            />
          </Cell>
        </Row>      
      </Tile>
    </Container>
  )
}