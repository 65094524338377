import React, {useState} from 'react';
import { Page } from './components/Display';
import { Form } from './views/Form';
import { List } from './views/List';
import { Nav } from './views/Nav';
import { Info } from './views/Info';
import { Settings } from './views/Settings';
import { ErrPage } from './views/ErrPage';
import { LocalData } from './data/LocalData';
import { KEY_LANGUAGE, KEY_VIEW, KEY_THEME, KEY_UNIT} from './data/DataKeys';

// load from storage
const appView     = LocalData.getKey(KEY_VIEW)      ?? 'form';
const appTheme    = LocalData.getKey(KEY_THEME)     ?? 'dark';
const appLanguage = LocalData.getKey(KEY_LANGUAGE)  ?? 'en';
const appUnit     = LocalData.getKey(KEY_UNIT)      ?? 'metric';

function App() {
  const [view, setView] = useState(appView)
  const viewState = {view, setView};
  const [theme, setTheme] = useState(appTheme);
  const themeState = {theme, setTheme};
  const [language, setLanguage] = useState(appLanguage);
  const languageState = {language, setLanguage}
  const [unit, setUnit] = useState(appUnit);
  const unitState = {unit, setUnit}
  const views = [
    {
      name:'errpage',
      components:[<ErrPage language={language} theme={theme}/>]
    },
    {
      name:'form',
      components:[<Form language={language} theme={theme} unit={unit} />]
    },
    {
      name:'info',
      components:[<Info language={language} theme={theme} unit={unit}/>]
    },
    {
      name:'list',
      components:[<List language={language} theme={theme} unit={unit}/>]
    },
    {
      name:'settings',
      components:[<Settings languageState={languageState} themeState={themeState} unitState={unitState}/>]
    },
  ];
  
  return (
      <Page theme={theme}>
        <Nav viewState={viewState} language={language} theme={theme} />
        {views.filter(views => views.name === view).map((filterView) =>(
          <>
            {filterView.components}
          </>
        ))}
      </Page>
  );
  
}

export default App;
