import React, {useState} from 'react';
import { KEY_THEME_DARK } from '../data/DataKeys';

// stateless
const DropDownSelect = (props) => {
    const selectOptionStyle = {
        display:'flex',
        flex:1,
        fontSize: 'calc(12px + 1vmin)',
        maxWidth:'108px',
        height:'28px',
        padding:'1px 3px',
        margin:'1px'
    }
    return (
        <select style={selectOptionStyle} onChange={props.onChange} value={props.value}>
            {props.children}
        </select>
    )
}
// stateless
const DropDownOption = (props) => {
    return (
        <option value={props.value}>{props.text}</option>
    )
}
// stateful 
const ImageButton = (props) => {
    const [isFocus, setIsFocus] = useState(false);
    const darkStyle = {
        backgroundImage: `url(${props.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor:'transparent',
        filter:'saturate(1)',
        height:'28px',
        width:'28px',
        margin:'3px',
        border:'0px',
        cursor:'pointer',
    }
    const darkHoverStyle = {
        backgroundImage: `url(${props.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor:'transparent',
        filter:'saturate(3)',
        height:'28px',
        width:'28px',
        margin:'3px',
        border:'0px',
        padding:'5px',
        cursor:'pointer', 
    }
    const lightStyle = {
        backgroundImage: `url(${props.image}) `,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor:'transparent',
        filter:'contrast(0%) saturate(0)',
        height:'28px',
        width:'28px',
        margin:'3px',
        border:'0px',
        cursor:'pointer',
    }
    const lightHoverStyle = {
        backgroundImage: `url(${props.image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor:'transparent',
        backgroundBlenMode:'overlay',
        filter:'contrast(50%) saturate(5)',
        height:'28px',
        width:'28px',
        margin:'3px',
        border:'0px',
        padding:'5px',
        cursor:'pointer', 
    }

    const theme = (props.theme === KEY_THEME_DARK ? darkStyle : lightStyle);
    const themeHover = (props.theme === KEY_THEME_DARK ? darkHoverStyle : lightHoverStyle);
    return (
        <button 
            style={isFocus ? themeHover : theme} 
            onClick={props.onClick} 
            onMouseEnter={() => setIsFocus(true)}
            onMouseLeave={() => setIsFocus(false)}
            title={props.title}
        ></button>
    )
}
// stateless
const InputDecimal = (props) => {
    const inputStyle = {
        display:'flex',
        flex:1,
        fontSize: 'calc(12px + 1vh)',
        maxWidth:'100px',
        height:'20px',
        margin:'1px'
    }
    return (
        <input 
            type={"number"} 
            step={props.step} 
            placeholder={props.placeholder}
            value={props.value}   
            onChange={props.onChange}
            style={inputStyle}
        />
    )
}
// stateless
const InputNumber = (props) => {
    const inputStyle = {
        display:'flex',
        flex:1,
        fontSize: 'calc(12px + 1vh)',
        maxWidth:'100px',
        height:'20px',
        margin:'1px'
    }
    return (
        <input 
            type={"number"} 
            step={1} 
            placeholder={props.placeholder}
            value={props.value}   
            onChange={props.onChange}
            style={inputStyle}
        />
    )
}

//
export  {
    DropDownSelect,
    DropDownOption,
    ImageButton,
    InputDecimal,
    InputNumber,
}