import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// StrictMode: is a tool for highlighting potential problems in an application,
// it only works in development, does not effect the productions build
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

